import React, { useEffect, useState } from 'react';
import { NavLink, Route, useLocation } from 'react-router-dom';
import { Loader, Menu, Sidebar } from 'semantic-ui-react';
import { StoryData } from 'storyblok-js-client';
import { useQuery } from '@apollo/react-hooks';
import classNames from 'classnames';
import { AnimatedSwitch } from 'react-router-transition';
import { gql } from 'apollo-boost';

import 'fomantic-ui-css/semantic.css';
import 'fomantic-ui-css/semantic.js';

import './styles/fomantic-overrides.sass';
import styles from './styles/Application.module.sass';
import { Content } from '@tjclifton/storyblok-react-utils';
import Page from './components/storyblok/Page';
import { useMemoOne } from 'use-memo-one';


/**
 *
 */
interface PageContent {
  readonly headerTitle: string;
  readonly headerImageUrl: Readonly<{
    url: string;
  }>;
  readonly headerCallout: string;
  readonly headerSubtitle: string;
  readonly headerSize: string;
  readonly backgroundPosition: string;
  readonly image: Readonly<{
    backgroundSize: string;
    filename: string;
  }>
}

/**
 *
 */
interface PageStoryData extends StoryData<PageContent> {
  readonly path: string;
}

/**
 *
 */
interface PageQueryResults {
  readonly PageItems: Readonly<{
    items: ReadonlyArray<PageStoryData>
  }>
}

const navigationItemsQuery = gql`{
  PageItems(filter_query_v2: {in_navigation: {in: "true"}}, sort_by: "content.navigationOrder:asc") {
    items {
      id
      name
      full_slug
      path
    }
  }
}`;

const pagesQuery = gql`{
  PageItems {
    items {
      id
      name
      full_slug
      path
    }
  }
}`;

const pageQuery = gql`
  query GetPage($typeId: ID!) {
    PageItem(id: $typeId) {
      id
      name
      slug
      parent_id
      content {
        _uid
        component
        body
        description
        headerType
        headerTitle
        headerImageUrl {
          url
        }
        headerCallout
        headerSubtitle
        headerSize
        backgroundPosition
        backgroundSize
        image {
          filename
        }
      }
    }
  }`;

/**
 *
 * @param _props
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Application: React.FC = props => {
  const [ loaded, setLoaded ] = useState(false);
  const [ sidebarVisible, setSidebarVisible ] = useState(false);
  const location = useLocation();

  const { loading: navigationLoading, data: navigationData } = useQuery(navigationItemsQuery, {
    fetchPolicy: 'cache-first',
  });

  const { data: pagesData } = useQuery<PageQueryResults>(pagesQuery, {
    fetchPolicy: 'cache-first',
  });

  /**
   *
   * @param item
   * @returns
   */
  const isRootItem = (item: PageStoryData) => item.path === '/';

  /**
   *
   * @param item
   * @returns
   */
  const pathFor = (item: PageStoryData) => isRootItem(item) ?
    '/' : `/${item.path || item.full_slug}`;


  const notFoundPage = useMemoOne(
    () => pagesData?.PageItems.items.find(item => pathFor(item) === '/404'),
    [pagesData]
  );

  /**
   * When the page changes...
   */
  useEffect(() => {
    // Scroll to the top
    window.scrollTo(0, 0);

    // Stop showing the side-menu if a page changes
    setSidebarVisible(false);
  }, [location]);

  /**
   *
   * @returns
   */
  const renderNavigationLinks = () =>
    navigationData?.PageItems.items.map((item: PageStoryData) =>
      <NavLink
        exact
        to={pathFor(item)}
        className="menu item"
        activeClassName={'active'}
        key={item.id}
      >
        {item.name}
      </NavLink>
    );

  /**
   *
   * @param page
   * @returns
   */
  const renderPage = (page: PageStoryData) =>
    <Content type="page"
      id={page.id}
      pageQuery={pageQuery}
      Component={Page}
      onLoaded={() => setLoaded(true)}
    >
      {data =>
        <Page
          data={data}
          onMenuButtonClick={() => setSidebarVisible(true)}
          renderNavigation={renderNavigationLinks} />
      }
    </Content>;

  /**
   *
   * @returns
   */
  const renderApp = () => <>
    {!loaded && <Loader active size="large" className="orange elastic" />}
    <Sidebar.Pushable className={classNames(styles.pushable, {[styles.active]: sidebarVisible})}>
      <Sidebar
        as={Menu}
        visible={sidebarVisible}
        onHide={() => setSidebarVisible(false)}
        animation="push"
        inverted
        vertical
      >
        {renderNavigationLinks()}
      </Sidebar>

      <Sidebar.Pusher className={styles.pusher} dimmed={sidebarVisible}>
        <AnimatedSwitch
          atEnter={{ opacity: 0 }}
          atLeave={{ opacity: 0 }}
          atActive={{ opacity: 1 }}
          className={styles.routeWrapper}
        >
          {pagesData?.PageItems.items.map(page =>
            <Route exact path={pathFor(page)} key={page.id}>
              {renderPage(page)}
            </Route>
          )}
          <Route>
            {pagesData ?
              notFoundPage ?
                renderPage(notFoundPage)
              :
                'Not found'
            :
              null
            }
          </Route>
        </AnimatedSwitch>
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  </>;

  /**
   *
   * @returns
   */
  const renderLoading = () =>
    <Loader active size="large" className="orange elastic" />;

  return navigationLoading ? renderLoading() : renderApp();
};

export default Application;
