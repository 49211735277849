import React from 'react';
import { Helmet } from 'react-helmet';
import { DynamicComponent, Paragraph, ParagraphBlok, Visibility } from '@tjclifton/storyblok-react-utils';
import { Container, Grid, GridColumn, Header, Menu, Segment } from 'semantic-ui-react';
import { StoryblokComponent, StoryData } from 'storyblok-js-client';

import styles from '@styles/Page.module.sass';
import MediaQuery from 'react-responsive';
import classNames from 'classnames';
import { data } from 'jquery';
import PageHeader, { PageHeaderPropTypes } from '../PageHeader';

/**
 *
 */
export interface StoryblokPageComponent extends StoryblokComponent<'page'> {
  readonly body: ReadonlyArray<StoryblokComponent<string>>;

  readonly description?: string;

  readonly image?: {
    readonly filename: string;
  };

  readonly headerType: 'large' | 'small' | 'none';

  readonly headerTitle?: string;

  readonly headerImageUrl?: {
    readonly url: string;
  };

  readonly headerCallout?: ReadonlyArray<ParagraphBlok>;

  readonly headerSubtitle?: string;

  readonly headerSize?: string;

  readonly backgroundPosition?: string;

  readonly backgroundSize?: string;
}

/**
 *
 */
export interface PagePropTypes {
  readonly data: StoryData<StoryblokPageComponent>;

  /**
   *
   */
  readonly renderNavigation?: () => JSX.Element | null;

  /**
   *
   */
  readonly onMenuButtonClick?: PageHeaderPropTypes['onMenuButtonClick'];
}

/**
 *
 * @param props
 * @returns
 */
const Page: React.FC<PagePropTypes> = props => {
  const { data: { content: blok } } = props;

  return <div>
    <Helmet titleTemplate="The Compromised Christianity of ACL | %s">
      <meta
        property="og:title"
        content={props.data.name}
      />
      <meta property="og:url" content={window.location.href} />
      <title>{props.data.name}</title>
    </Helmet>

    <PageHeader
      type={blok.headerType}
      backgroundImageUrl={blok.headerImageUrl?.url}
      backgroundPosition={blok.backgroundPosition}
      backgroundSize={blok.backgroundSize}
      height={blok.headerSize}
      onMenuButtonClick={props.onMenuButtonClick}
    >
      <Grid centered verticalAlign="middle" stretched>
        <GridColumn width={16} textAlign="center">
          <div className={styles.title}>
            <Header as="h1">{blok.headerTitle || data.name}</Header>

            {blok.headerSubtitle && <Header sub>{blok.headerSubtitle}</Header>}

            {blok.headerCallout?.length ?
              <Visibility only="lg">
                <Container className={styles.callout}>
                  {blok.headerCallout?.map(content =>
                    <Paragraph blok={content} key={content._uid} />
                  )}
                </Container>
              </Visibility>
            :
              null
            }
          </div>
        </GridColumn>
      </Grid>
    </PageHeader>

    <Container
      className={classNames(
        styles.container
      )}
    >
      <Visibility above="sm">
        <nav className={styles.nav}>
          <Menu secondary pointing>
            {props.renderNavigation?.()}
          </Menu>
        </nav>
      </Visibility>

      <section className={styles.content}>
        <MediaQuery maxWidth={725}>
          <Container className={styles.callout}>
            {blok.headerCallout?.map(content =>
              <Paragraph blok={content} key={content._uid} />
            )}
          </Container>
        </MediaQuery>

        {blok.body?.map(content =>
          <DynamicComponent blok={content} key={content._uid} />
        )}
      </section>
      <Segment basic textAlign="center">
        Copyright &copy; 2021. A ministry of <a href="http://thechurchofadelaide.com">The Church of Adelaide</a>.
      </Segment>
    </Container>
  </div>;
};

export default Page;
