import React from 'react';

import styles from '@styles/PageHeader.module.sass';
import { Button, Header, Icon } from 'semantic-ui-react';
import { useResponsiveValue, Visibility } from '@tjclifton/storyblok-react-utils';

/**
 *
 */
export interface PageHeaderPropTypes {
  readonly type: 'large' | 'small' | 'none';
  readonly backgroundImageUrl?: string;
  readonly backgroundPosition?: string;
  readonly backgroundSize?: string;
  readonly height?: string;

  readonly onMenuButtonClick?: () => void
}

/**
 *
 * @param props
 */
const PageHeader: React.FC<PageHeaderPropTypes> = props => {
  const headerStyles = useResponsiveValue({max: 'sm'}, {
    margin: '0 3rem 0 3rem'
  }, {});

  return <header>
    <Visibility max="sm">
      <Button
        className={styles.menuButton}
        onClick={props.onMenuButtonClick}
        basic
        inverted
        icon={<Icon name="bars"></Icon>}
      ></Button>
    </Visibility>

    {props.type === 'large' || props.type === 'small' ?
      <div className={styles[props.type]} style={{
        backgroundPosition: props.backgroundPosition,
        backgroundSize: props.backgroundSize,
        backgroundImage: `url("${props.backgroundImageUrl}")`,
        height: props.height
      }}>
        {props.children}
      </div>
    :
      <div className={styles.none}>
        <Header size="medium" textAlign="center" style={headerStyles}>
          The Compromised Christianity of the ACL
        </Header>
      </div>
    }
  </header>;
};

export default PageHeader;
