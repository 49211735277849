import ApolloClient from 'apollo-boost';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import Application from './Application';
import reportWebVitals from './reportWebVitals';

const client = new ApolloClient({
  uri: 'https://gapi.storyblok.com/v1/api',
  request: operation => {
    operation.setContext({
      headers: {
        token: process.env.REACT_APP_STORYBLOK_PREVIEW_TOKEN,
        version: 'draft'
      }
    });
  }
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Router>
        <Application />
      </Router>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
